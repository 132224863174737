<template>
  <div class="main">
    <b-tabs
      v-model="tabIndex"
      style="width: 100%"
    >
      <b-tab>
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("OrderList") }}</strong>
        </template>
        <div style="padding-top: 20px">
          <InternalMoving
            @goTab2="goToTab2"
          />
        </div>
      </b-tab>
      <b-tab @click="callInPending">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("FactoryLog") }}</strong>
        </template>
        <div style="padding-top: 20px">
          <InPendingArticles />
        </div>
      </b-tab>
      <b-tab @click="goToTab3">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("ShipToManufactory") }}</strong>
        </template>
        <div style="padding-top: 20px">
          <ShippedArticles />
        </div>
      </b-tab>
      <b-tab @click="callInStore">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("StoreLog") }}</strong>
        </template>
        <div style="padding-top: 20px">
          <ShippedToStore />
        </div>
      </b-tab>
      <b-tab @click="goToTab4">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("ShipToStore") }}</strong>
        </template>
        <div style="padding-top: 20px">
          <ShippedArticlesStore />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import InternalMoving from '@/views/InternalMoving.vue'
import InPendingArticles from '@/components/orders/InPendingArticles.vue'
import ShippedArticles from '@/components/orders/ShippedArticles.vue'
import ShippedArticlesStore from '@/components/orders/ShippedArticlesStore.vue'

import ShippedToStore from '@/components/orders/ShippedToStore.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    InternalMoving,
    InPendingArticles,
    ShippedArticles,
    ShippedToStore,
    ShippedArticlesStore,
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  computed: {

  },
  mounted() {
    this.orderItemStates()
  },
  methods: {
    ...mapActions(['filterItemMovement', 'orderItemStates', 'filterOrderMovement', 'teamNames']),
    callInPending() {
      this.filterItemMovement({
        state: 'Pending',
        pageNumber: 1,
        pageSize: 15,
      })
      this.teamNames({
        pageNumber: 1,
        pageSize: 15,
      })
    },
    callInStore() {
      this.teamNames({
        pageNumber: 1,
        pageSize: 15,
      })
    },
    goToTab2() {
      this.tabIndex = 1
      this.filterItemMovement({
        state: 'Pending',
        pageNumber: 1,
        pageSize: 15,
      })
      this.teamNames({
        pageNumber: 1,
        pageSize: 15,
      })
    },
    goToTab3() {
      this.filterOrderMovement({
        shipType: 'ShipOrderItemToManufactory',
        deliverDate: null,
        pageNumber: 1,
        pageSize: 15,
      })
      this.teamNames({
        pageNumber: 1,
        pageSize: 15,
      })
    },
    goToTab4() {
      this.filterOrderMovement({
        shipType: 'ShipOrderItemToStore',
        deliverDate: null,
        pageNumber: 1,
        pageSize: 15,
      })
      this.teamNames({
        pageNumber: 1,
        pageSize: 15,
      })
    },
  },
}
</script>

<style scoped>
.main{
  display: flex;
  justify-content: flex-start !important;
  gap: 10px;
}
@media screen and (max-width: 1200px) {
  .main{
    padding: 90px 15px 15px 20px;
}
}
</style>
