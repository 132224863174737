<template>
  <div class="internal">
    <InternalMovingMaterials
      @tab2="goToTab2"
    />
    <AddToInternalMoving />
  </div>
</template>

<script>
import InternalMovingMaterials from '@/components/orders/InternalMovingMaterials.vue'
import AddToInternalMoving from '@/components/orders/AddToInternalMoving.vue'

export default {
  components: {
    InternalMovingMaterials,
    AddToInternalMoving,
  },
  methods: {
    goToTab2() {
      this.$emit('goTab2')
    },
  },
}
</script>

<style scoped>
.internal {
  display:flex;
  width: 100%;
  gap: 20px;
}

@media screen and (max-width: 1200px) {
  .internal {
    display:flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }
}
</style>
