import { render, staticRenderFns } from "./DetailsShopManu.vue?vue&type=template&id=3e7b54b6&scoped=true"
import script from "./DetailsShopManu.vue?vue&type=script&lang=js"
export * from "./DetailsShopManu.vue?vue&type=script&lang=js"
import style0 from "./DetailsShopManu.vue?vue&type=style&index=0&id=3e7b54b6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e7b54b6",
  null
  
)

export default component.exports