import { render, staticRenderFns } from "./ShippedToStore.vue?vue&type=template&id=18afe12f&scoped=true"
import script from "./ShippedToStore.vue?vue&type=script&lang=js"
export * from "./ShippedToStore.vue?vue&type=script&lang=js"
import style0 from "./ShippedToStore.vue?vue&type=style&index=0&id=18afe12f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18afe12f",
  null
  
)

export default component.exports