<template>
  <div>
    <b-modal
      id="confirmation-modal"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      :title="title"
      hide-footer
    >
      {{ bodyText }}
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Confirm') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['title', 'bodyText', 'isConfirmed'],
  data() {
    return {
      modalTriggeredByToggle: false,
    }
  },
  methods: {
    ...mapActions(['loadSaturdayWork']),
    onSubmit() {
      this.$emit('Confirm', true)
      this.$refs.modal.hide()
    },
    onCancel() {
      if (!this.isConfirmed) {
        this.loadSaturdayWork();
      }
      this.$emit('saturdayy', this.saturday)
      this.modalTriggeredByToggle = false;
      this.$refs.modal.hide();
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}

</style>
